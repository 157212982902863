import React, { useEffect } from 'react';
import { onErrorDefault, onReadyDefault, onSubmitDefault } from '../util/initial';
import { initBrick } from '../util/renderBrick';
import { DEBOUNCE_TIME_RENDER } from '../util/constants';
/**
 * Wallet Brick allows you to offer payments from your Mercado Pago account at any stage of the purchase process.
 *
 * Usage:
 *
 * ```ts
 * import Wallet, {initMercadoPago} from '@mercadopago/sdk-react'
 *
 * initMercadoPago('YOUR_PUBLIC_KEY')
 *
 * const Example = () => {
 *   return(
 *     <Wallet initialization={{ preferenceId: '<PREFERENCE_ID>'}} /> // PREFERENCE_ID generated in backend
 *   )
 * }
 * export default Example
 * ```
 *
 * @see {@link https://www.mercadopago.com/developers/en/docs/checkout-bricks/wallet-brick/introduction Wallet Brick documentation} for more information.
 */
const Wallet = ({ onReady = onReadyDefault, onError = onErrorDefault, onSubmit = onSubmitDefault, customization, initialization, locale, }) => {
    useEffect(() => {
        // CardPayment uses a debounce to prevent unnecessary reRenders.
        let timer;
        const WalletBrickConfig = {
            settings: {
                initialization,
                customization,
                locale,
                callbacks: {
                    onReady: onReady,
                    onSubmit: onSubmit,
                    onError: onError,
                },
            },
            name: 'wallet',
            divId: 'walletBrick_container',
            controller: 'walletBrickController',
        };
        timer = setTimeout(() => {
            initBrick(WalletBrickConfig);
        }, DEBOUNCE_TIME_RENDER);
        return () => {
            var _a;
            clearTimeout(timer);
            (_a = window.walletBrickController) === null || _a === void 0 ? void 0 : _a.unmount();
        };
    }, [customization, initialization, onReady, onError, onSubmit]);
    return React.createElement("div", { id: "walletBrick_container" });
};
export default Wallet;
